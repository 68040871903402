import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/smarthome',
      name: 'smarthone',
      component: () => import('./views/Smarthome.vue')
    },
    {
      path: '/smartbuilding',
      name: 'smartbuilding',
      component: () => import('./views/Smartbuilding.vue')
    },
    {
      path: '/mobilite-electrique',
      name: 'mobilite-electrique',
      component: () => import('./views/MobiliteElectrique.vue')
    },
    {
      path: '/references',
      name: 'references',
      component: () => import('./views/References.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/apropos',
      name: 'apropos',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Apropos.vue')
    }
  ],
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
})
