<template>
  	<div>
    	<div class="row container-fluid m0">
    		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 m0 centered">
    			<img src="img/maison.jpg" alt="" class="w_inherit slider">
    		</div>
    	</div>
    	<div class="row mptop20 container-fluid">
    		<div class="col-12 centered">
    			<h2>Nos services</h2>
    		</div>
    		<div class="col-12 centered ">
    			<div class="_f">
    				<div class="product-min">
    					<img src="img/no-image.jpg" alt="" class="product-thumb">
    					<h4 class="product-name">Smart home</h4>
    					<a href="" class="add-product">Lorem ipsum dolor</a>
    				</div>
    				<div class="product-min">
    					<img src="img/no-image.jpg" alt="" class="product-thumb">
    					<h4 class="product-name">Smart building</h4>
                        <a href="" class="add-product">Lorem ipsum dolor</a>
    				</div>
    				<div class="product-min">
    					<img src="img/no-image.jpg" alt="" class="product-thumb">
    					<h4 class="product-name">Mobilité électrique</h4>
                        <a href="" class="add-product">Lorem ipsum dolor</a>
    				</div>
    				<br><br>
    				<!--a href="./" class="btn">Voir plus</a-->
    			</div>
    		</div>
    	</div>
  	</div>
</template>

<script>

export default {
	  	name: 'index',
	  	components: {

	  	}
}
</script>
