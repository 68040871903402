<template>
    <div id="app">
        <div class="overlay"></div>
        <header class="">
            <div class="social">
                <a href="">
                    <i class="fa fa-facebook"></i> <span>Facebook</span>
                </a>
                <a href="">
                    <i class="fa fa-linkedin"></i> <span>LinkedIn</span>
                </a>
                <a href="">
                    <i class="fa fa-instagram"></i> <span>Instagram</span>
                </a>
            </div>
            <div class="menu-container">
                <router-link to="/" class="logo"></router-link>
                <nav>
                    <a href="./" id="toggle-menu">
                        <i class="fa fa-bars"></i>
                    </a>
                    <div>
                        <router-link to="/apropos">A propos</router-link>
                        <router-link to="/smarthome">Smart home</router-link>
                        <router-link to="/smartbuilding">Smart building</router-link>
                        <router-link to="/mobilite-electrique">Mobilité électrique</router-link>
                        <router-link to="/references">Références</router-link>
                        <router-link to="/contact">Contact</router-link>
                    </div>
                </nav>
                <!--div class="basket">
                    <div class="cart-count">
                        <!-i class="fa fa-shopping-cart"></i->
                        <span>0</span>
                    </div>
                    <div class="basket-content">
                        <div class="details">
                            Votre panier est vide. :(
                        </div>
                        <div class="">
                            <div class="price_content">
                                <div class="cart-total price_inline">
                                    <span class="label">Total : </span>
                                    <span class="value"></span>
                                </div>
                            </div>
                            <div class="checkout">
                                <a href="panier" class="order_now">Commander</a>
                                <a class="closeOverlay continue-shopping" href="#">Continuer mes courses</a>
                            </div>
                          </div>
                    </div>
                </div-->
            </div>
        </header>
        <section class="main-container">
            <router-view/>
        </section>
        <footer>
            <div class="_f">
                <div class="row container-fluid">
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <router-link to="/" class="logo white-logo"></router-link>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <h3 class="white">Services</h3>
                        <ul>
                            <li>
                                <router-link to="/smarthome">Smart home</router-link>
                            </li>
                            <li>
                                <router-link to="/smartbuilding">Smart building</router-link>
                            </li>
                            <li>
                                <router-link to="/mobilite-electrique">Mobilité électrique</router-link>
                            </li>
                            <li>
                                <router-link to="/references">Références</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 align-r">
                        <h3 class="white">Contacts</h3>
                        <ul>
                            <li>
                                <router-link to="/devis">Devis</router-link>
                            </li>
                            <li>
                                <router-link to="/devis">Contact</router-link>
                            </li>
                            <li>
                                <router-link to="/devis">Partenaires</router-link>
                            </li>
                            <li>
                                <div class="social">
                                    <a href="">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <a href="">
                                        <i class="fa fa-linkedin"></i>
                                    </a>
                                    <a href="">
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <h3 class="white">Newsletter</h3>
                        <form>
                            <input type="email" name="" placeholder="Adresse email">
                            <!--input type="submit" name="" class="btn lite-border" value="Je m'inscris"-->
                            <button class="btn fa fa-paper-plane"></button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row container-fluid copyright">
                <div class="col-12 centered">
                    <p class="white thin">
                        &copy; 2020 - <a href="/">Mentions légales</a> -
                            <a href="/">Politique de confidentialité</a>
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    $(window).on('scroll', function (e) {
        if ($(this).scrollTop() > 50) {
            $('header').addClass('fixed')
            $('.main-container').addClass('hfx')
        } else {
            $('header').removeClass('fixed')
            $('.main-container').removeClass('hfx')
        }
    });
    $(function(){
        $("#toggle-menu").on('click', function(e){
            e.preventDefault();
            $('header .menu-container nav > div').slideToggle(0);
        });
        $("header .menu-container nav > div a").on('click', function(){
            if($("#toggle-menu").is(':visible'))
                $('header .menu-container nav > div').fadeOut(0);
        });

        $("img.product-thumb").zoomify({
            duration: 200,
            easing: "linear",
            scale: .9
        });

        $(".basket .cart-count").on("click", function(t) {
            t.preventDefault(), $(".basket").hasClass("on") ? ($(".basket").removeClass("on"), $(".overlay").removeClass("on")) : ($(".basket").addClass("on"), $(".overlay").addClass("on"))
        });

        $(".overlay, .closeOverlay").on("click", function(t) {
            t.preventDefault(), $(".basket").removeClass("on"), $(".overlay").removeClass("on")
        })
    });

</script>
